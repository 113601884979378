import { Model } from "@vuex-orm/core";

/**
 * The type of level for MindPod activities
 */
export const LevelType = Object.freeze({
  TUTORIAL: "tutorial",
  BATTLE_FISH: "battleFish",
  BATTLE_SHARK: "battleShark",
  REVOLUTION_JUMP: "revolutionJump",
});
/**
 * The type of entity for MindPod activities
 */
export const EntityType = Object.freeze({
  FISH_NEMESES: "FISH_NEMESES",
  SHARK_NEMESES: "SHARK_NEMESES",
});
/**
 * The Species for MindPod activities
 */
export const Species = Object.freeze({
  MACKEREL: "MACKEREL",
  COD: "COD",
  SALMON: "SALMON",
  HERRING: "HERRING",
  BULL_SMALL: "BULL_SMALL",
  KINGMACKEREL: "KINGMACKEREL",
  DOGFISH_SMALL: "DOGFISH_SMALL",
  MAHIMAHI: "MAHIMAHI",
  REDSNAPPER: "REDSNAPPER",
  REDFISH: "REDFISH",
  MULLET: "MULLET",
  RAINBOWRUNNER: "RAINBOWRUNNER",
  LEMON_LARGE: "LEMON_LARGE",
  BLUE_LARGE: "BLUE_LARGE",
  BLACKTIP_SMALL: "BLACKTIP_SMALL",
  TIGER_LARGE: "TIGER_LARGE",
  WHITETIP_SMALL: "WHITETIP_SMALL",
  HAMMERHEAD_LARGE: "HAMMERHEAD_LARGE",
  MAKO_LARGE: "MAKO_LARGE",
  GREATWHITE_LARGE: "GREATWHITE_LARGE",
});

class MindPodActivityEntity extends Model {
  static entity = "mindPodEntities";

  static fields() {
    return {
      entityType: this.attr(null),
      species: this.attr(null),
      entityNumber: this.attr(0),
      entityLives: this.attr(0),
      keepEntityInflock: this.attr(false),
      entityAdditionalVelocity: this.attr(0),
      entityStunTimeSeconds: this.attr(0),
    };
  }
}

export default class MindPodActivityDescription extends Model {
  static entity = "mindPodActivities";

  static fields() {
    return {
      id: this.attr(null), // Primary key
      level: this.attr(null),
      levelType: this.attr(null),
      useDolphinLives: this.attr(false),
      dolphinLives: this.attr(0),
      dolphinBaseSpeedMS: this.attr(0),
      dolphinAdditionalVelocity: this.attr(0),
      allowBoost: this.attr(false),
      allowBolt: this.attr(false),
      jumpLevelDurationSeconds: this.attr(0),
      defaultAimAssistValue: this.attr(0),
      entities: this.attr([], (entities) =>
        entities.map((e) => new MindPodActivityEntity(e))
      ),
    };
  }

  // Network methods

  // Returns the list of all MindPod activities
  static fetchAllMindPodActivityInformation(signal) {
    return this.api().get("/mindpod/level", { dataKey: "result", signal });
  }
}
