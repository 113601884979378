/*
  The date utils mainly uses date-fns for date formatting and transformations
  https://date-fns.org/docs/Getting-Started
 */

import {
  format as formatFns,
  subDays,
  isSameDay,
  isSameYear,
  startOfWeek,
  endOfWeek,
  getWeek,
  subYears,
} from "date-fns";
import { fr, it, es, ro, enGB, enUS, de, ar } from "date-fns/locale";
import store from "@/store";
import { defaultLocale, getLocaleFrom } from "@/utils/locales";

const yyyyMMdd = "yyyy-MM-dd";
const DMMMMYYYY = "d MMMM yyyy";
const DDMMMYYYY = "dd MMM yyyy";
const DDMMM = "dd MMM";
const DMMMM = "d MMMM";
const EEEEDMMMM = "EEEE, d MMMM"; // Monday, 1
const EEEEDMMMMYYYY = "EEEE, d MMMM yyyy"; // Monday, 1 january 1900
const MMMMYYYY = "MMMM yyyy";
const DDMMMYYYYHHmm = "dd MMM yyyy HH:mm";
const DDMMMYYYYHHmmA = "dd MMM yyyy hh:mm a";
const DDMMMYYYYHHmmssSSS = "dd MMM yyyy HH:mm:ss.SSS";
const DDMMMYYYYHHmmssSSSA = "dd MMM yyyy hh:mm:ss.SSS a";
const DDMMMYYYYHHMMss = "dd MMM yyyy HH:mm:ss";
const DDMMMYYYYHHMMssA = "dd MMM yyyy hh:mm:ss a";
const Hmm = "H:mm";
const HmmA = "h:mm a";
const HHmm = "HH:mm";
const HHmmA = "hh:mm a";
const MMDD = "MM dd";

/**
 * date-fns Locale object used for different time/date localization purposes.
 */
const locales = {
  "en-GB": enGB,
  "en-US": enUS,
  fr: fr,
  it: it,
  es: es,
  ro: ro,
  de: de,
  ar: ar,
};

function getDefaultBirthDate() {
  return subYears(new Date(), 70);
}

function format(value, format) {
  // check if value is a date
  if (value instanceof Date) {
    return formatFns(value, format, {
      locale: locales[store.getters["Language/language"]],
    });
  }

  // If value is null or undefined return empty string
  if (!value) {
    return "";
  }

  // if it is not a date format it to date
  if (!(value instanceof Date)) {
    value = new Date(value);
  }

  return formatFns(value, format, {
    locale: locales[store.getters["Language/language"]],
  });
}

function use12HourFormat() {
  return getLocaleFrom(store.getters["Language/language"]) === defaultLocale;
}

/**
 * Format the date to a string with the format yyyyMMdd
 * Example: 2024-12-06
 * @param {date} value
 */
function formatToyyyyMMdd(value) {
  return format(value, yyyyMMdd);
}

/**
 * Format the date to a string with the format DDMMM
 * Example: 06 Dec
 * @param {date} value
 */
function formatToDDMMM(value) {
  return format(value, DDMMM);
}

/**
 * Format the date to a string with the format DMMMM
 * Example: 06 December
 * @param {date} value
 */
function formatToDMMMM(value) {
  return format(value, DMMMM);
}

/**
 * Format the date to a string with the format EEEEDMMMM
 * Example: Friday, 6 December
 * @param {date} value
 */
function formatToEEEEDMMMM(value) {
  return format(value, EEEEDMMMM);
}

/**
 * Format the date to a string with the format EEEEDMMMMYYYY
 * Example: Friday, 6 December 2024
 * @param {date} value
 */
function formatToEEEEDMMMMYYYY(value) {
  return format(value, EEEEDMMMMYYYY);
}

/**
 * Format the date to a string with the format DDMMMYYYY
 * Example: 06 Dec 2024
 * @param {date} value
 */
function formatToDDMMMYYYY(value) {
  return format(value, DDMMMYYYY);
}

/**
 * Format the date to a string with the format DMMMMYYYY
 * Example: 6 December 2024
 * @param {date} value
 */
function formatToDMMMMYYYY(value) {
  return format(value, DMMMMYYYY);
}

/**
 * Format the date to a string with the format DDMMMYYYYHHmmA or DDMMMYYYYHHmm
 * Examples:
 *  - use12hIfAvailable = true => 06 Dec 2024 10:25 PM
 *  - use12hIfAvailable = false => 06 Dec 2024 22:25
 * @param {date} value
 */
function formatToDDMMMYYYYHmm(value, use12hIfAvailable = true) {
  return format(
    value,
    use12HourFormat() && use12hIfAvailable ? DDMMMYYYYHHmmA : DDMMMYYYYHHmm
  );
}

/**
 * Format the date to a string with the format DDMMMYYYYHHMMssA or DDMMMYYYYHHMMss
 * Examples:
 *  - use12hIfAvailable = true => 06 Dec 2024 10:25:06 PM
 *  - use12hIfAvailable = false => 06 Dec 2024 22:25:06
 * @param {date} value
 */
function formatToDDMMMYYYYHHMMss(value, use12hIfAvailable = true) {
  return format(
    value,
    use12HourFormat() && use12hIfAvailable ? DDMMMYYYYHHMMssA : DDMMMYYYYHHMMss
  );
}

/**
 * Format the date to a string with the format DDMMMYYYYHHmmssSSSA or DDMMMYYYYHHmmssSSS
 * Examples:
 *  - use12hIfAvailable = true => 06 Dec 2024 10:25:06.930 PM
 *  - use12hIfAvailable = false => 06 Dec 2024 22:25:06.930
 * @param {date} value
 */
function formatToDDMMMYYYYHHmmssSSS(value, use12hIfAvailable = true) {
  return format(
    value,
    use12HourFormat() && use12hIfAvailable
      ? DDMMMYYYYHHmmssSSSA
      : DDMMMYYYYHHmmssSSS
  );
}

/**
 * Format the date to a string with the format HmmA or Hmm
 * Examples:
 *  - use12hIfAvailable = true => 8:25 PM
 *  - use12hIfAvailable = false => 20:25
 * @param {date} value
 */
function formatToHmm(value, use12hIfAvailable = true) {
  return format(value, use12HourFormat() && use12hIfAvailable ? HmmA : Hmm);
}

/**
 * Format the date to a string with the format HHmmA or HHmm
 * Examples:
 *  - use12hIfAvailable = true => 08:25 PM
 *  - use12hIfAvailable = false => 20:25
 * @param {date} value
 */
function formatToHHmm(value, use12hIfAvailable = true) {
  return format(value, use12HourFormat() && use12hIfAvailable ? HHmmA : HHmm);
}

/**
 * Format the date to a string with the format MMDD
 * Example: 12 06
 * @param {date} value
 */
function formatToMMDD(value) {
  return format(value, MMDD);
}

/**
 * Format the date to a string with the format MMMMYYYY
 * Example: December 2024
 * @param {date} value
 */
function formatToMMMMYYYY(value) {
  return format(value, MMMMYYYY);
}

/**
 * Returns whether the provided object is a valid date
 * @param date
 */
function isValidDate(date) {
  return date instanceof Date && !isNaN(date);
}

// Returns whether the current date is today
function isToday(date) {
  let today = new Date();
  return isSameDay(today, date);
}

function isYesterday(date) {
  let yesterday = subDays(new Date(), 1);
  return isSameDay(yesterday, date);
}

function isSameYearAsToday(date) {
  let today = new Date();
  return isSameYear(today, date);
}

// Returns whether the date is in the past
function isInThePast(date) {
  return date < new Date();
}

// Get the minimum time of the day for the given date
function getDayDate(date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
}

function startOfWeekForCurrentLanguage(date) {
  return startOfWeek(date, {
    locale: locales[store.getters["Language/language"]],
  });
}

function endOfWeekForCurrentLanguage(date) {
  return endOfWeek(date, {
    locale: locales[store.getters["Language/language"]],
  });
}

function getWeekForCurrentLanguage(date) {
  return getWeek(date, {
    locale: locales[store.getters["Language/language"]],
  });
}

export {
  getDefaultBirthDate,
  formatToyyyyMMdd,
  formatToDDMMMYYYYHHMMss,
  formatToDDMMMYYYYHHmmssSSS,
  formatToDDMMMYYYY,
  formatToDMMMMYYYY,
  formatToDDMMM,
  formatToDMMMM,
  formatToMMMMYYYY,
  formatToDDMMMYYYYHmm,
  formatToEEEEDMMMM,
  formatToEEEEDMMMMYYYY,
  formatToHmm,
  formatToHHmm,
  formatToMMDD,
  format,
  isValidDate,
  isToday,
  isYesterday,
  getDayDate,
  isInThePast,
  isSameYearAsToday,
  use12HourFormat,
  startOfWeekForCurrentLanguage,
  endOfWeekForCurrentLanguage,
  getWeekForCurrentLanguage,
};
