import Vue from "vue";
import Vuex from "vuex";
import VuexORM from "@vuex-orm/core";
import VuexORMAxios from "@vuex-orm/plugin-axios";
import Auth from "@/store/Auth.js";
import Language from "@/store/Language.js";
import Onboarding from "@/store/Onboarding.js";
import ZoomAccess from "@/store/ZoomAccess.js";
import Group from "@/models/Group.model";
import Profile from "@/store/Profile";
import ProfileModel from "@/models/Profile.model";
import PersonalSettings from "@/models/PersonalSettings.model";
import Customer from "@/models/Customer.model";
import License from "@/models/License.model";
import PatientLicense from "@/models/PatientLicense.model";
import MindPodLicense from "@/models/MindPodLicense.model";
import Site from "@/models/Site.model";
import SiteUnit from "@/models/SiteUnit.model";
import Timezone from "@/models/Timezone.model";
import ActivityDescription from "@/models/ActivityDescription.model";
import MindPodActivityDescription from "@/models/MindPodActivityDescription.model";
import Asset from "@/models/Asset.model";
import UserSession from "@/models/UserSession.model";
import AuditLog from "@/models/AuditLog.model";
import VideoMeeting from "@/models/VideoMeeting.model";
import VideoMeetingRequest from "@/models/VideoMeetingRequest.model";
import PatientSessionSummary from "@/models/PatientSessionSummary.model";
import { ProgramPreset } from "@/models/ProgramPreset.model";
import DayTrainingSummary from "@/models/DayTrainingSummary.model";
import PerformanceActivityResult from "@/models/PerformanceActivityResult.model";
import PerformanceActivityPlanningAndTraining from "@/models/PerformanceActivityPlanningAndTraining.model";
import CustomerNotification from "@/models/CustomerNotification.model";
import InstructionsForUse from "@/models/InstructionsForUse.model";
import AuthOptions from "@/models/AuthOptions.model";
import PasswordOptions from "@/models/PasswordOptions.model";
import ActivityPatientUsage from "@/models/ActivityPatientUsage.model";
import OptionalFeatureDefinition from "@/models/OptionalFeatureDefinition.model";
import OptionalFeatureActivation from "@/models/OptionalFeatureActivation.model";
import TrainingMaterial from "@/models/training-material/TrainingMaterial.model";
import TherapeuticGoal from "@/models/TherapeuticGoal.model";
import LevelRecommendation from "@/models/LevelRecommendation.model";
import FeedbackReport from "@/models/FeedbackReport.model";
import FeedbackReportAttachment from "@/models/FeedbackReportAttachment.model";
import WeeklyActiveTrainingTimeGoalAndCompletion from "@/models/WeeklyActiveTrainingTimeGoalAndCompletion.model";
import WeeklyActiveTrainingTimeGoal from "@/models/WeeklyActiveTrainingTimeGoal.model";
import PersonalAccessToken from "@/models/PersonalAccessToken.model";

Vue.use(Vuex);

VuexORM.use(VuexORMAxios);

const database = new VuexORM.Database();

database.register(Customer);
database.register(License);
database.register(PatientLicense);
database.register(MindPodLicense);
database.register(Site);
database.register(SiteUnit);
database.register(Timezone);
database.register(ActivityDescription);
database.register(MindPodActivityDescription);
database.register(ActivityPatientUsage);
database.register(Asset);
database.register(UserSession);
database.register(ProfileModel);
database.register(PersonalSettings);
database.register(AuditLog);
database.register(VideoMeeting);
database.register(VideoMeetingRequest);
database.register(PatientSessionSummary);
database.register(ProgramPreset);
database.register(DayTrainingSummary);
database.register(PerformanceActivityResult);
database.register(PerformanceActivityPlanningAndTraining);
database.register(CustomerNotification);
database.register(InstructionsForUse);
database.register(AuthOptions);
database.register(Group);
database.register(OptionalFeatureDefinition);
database.register(OptionalFeatureActivation);
database.register(TrainingMaterial);
database.register(PasswordOptions);
database.register(TherapeuticGoal);
database.register(LevelRecommendation);
database.register(FeedbackReport);
database.register(FeedbackReportAttachment);
database.register(WeeklyActiveTrainingTimeGoalAndCompletion);
database.register(WeeklyActiveTrainingTimeGoal);
database.register(PersonalAccessToken);

const store = new Vuex.Store({
  modules: {
    Auth,
    Language,
    Onboarding,
    Profile,
    ZoomAccess,
  },
  plugins: [VuexORM.install(database)],
});

/**
 * Clears all data locally stored on the webapp, including ORM data.
 */
const clearStores = () => {
  Promise.all([
    store.dispatch("Profile/clearStore"),
    store.dispatch("Onboarding/clearStore"),
    store.dispatch("ZoomAccess/clearStore"),
    store.dispatch("entities/deleteAll"),
    store.dispatch("Auth/clearStore"),
  ]).catch((e) => {
    // Show debug data only in development environment
    if (process.env.NODE_ENV === "development") {
      console.log(e);
    }
  });
};

/**
 * Clears all possible data from the previously selected patient.
 */
const clearSelectedPatientStoreData = () => {
  return Promise.all([
    store.dispatch("Profile/clearStore"),
    store.dispatch("Onboarding/clearStore"),
    UserSession.deleteAll(),
    ProgramPreset.deleteAll(),
    DayTrainingSummary.deleteAll(),
    PerformanceActivityResult.deleteAll(),
    PerformanceActivityPlanningAndTraining.deleteAll(),
    ActivityPatientUsage.deleteAll(),
    TherapeuticGoal.deleteAll(),
    WeeklyActiveTrainingTimeGoal.deleteAll(),
    WeeklyActiveTrainingTimeGoalAndCompletion.deleteAll(),
    LevelRecommendation.deleteAll(),
  ]).catch((e) => {
    // Show debug data only in development environment
    if (process.env.NODE_ENV === "development") {
      console.log(e);
    }
  });
};

export default store;
export { clearStores, clearSelectedPatientStoreData };
