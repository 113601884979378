import { Model } from "@vuex-orm/core";
import { formatToyyyyMMdd } from "@/utils/date";
import { parseISO } from "date-fns";

export default class WeeklyActiveTrainingTimeGoalAndCompletion extends Model {
  static entity = "weeklyActiveTrainingTimeGoalAndCompletion";

  static fields() {
    return {
      validFromDate: this.attr(null),
      validToDate: this.attr(null),
      patientUsername: this.attr(null),
      totalActiveTrainingTime: this.attr(0),
      supervisedActiveTrainingTime: this.attr(0),
      unsupervisedActiveTrainingTime: this.attr(0),
      weeklyActiveTrainingTimeGoal: this.attr(0),
      isGoalSet: this.attr(null),
      daysTrained: this.attr(null),
    };
  }

  get FromDate() {
    // N.B.: validFromDate is a Date Only, using parseISO() returns a local date
    // with the day unchanged
    return this.validFromDate ? parseISO(this.validFromDate) : null;
  }

  get ToDate() {
    // N.B.: validToDate is a Date Only, using parseISO() returns a local date
    // with the day unchanged
    return this.validToDate ? parseISO(this.validToDate) : null;
  }

  /**
   * Fetches computed weekly ATT data for the current patient
   * @param {string} username Patient username
   * @param {Date} from From date
   * @param {Date} to To date
   * @param {boolean} saveData Whether to save the data into the store
   * @param {AbortSignal} signal
   */
  static async fetchComputedWeeklyActiveTrainingTimeForDatesAsync(
    username,
    from,
    to,
    signal = null,
    saveData = true
  ) {
    const fromDate = formatToyyyyMMdd(from);
    const toDate = formatToyyyyMMdd(to);
    return await this.api().get(
      `/weekly-att/${username}/compute-sessions?fromDate=${fromDate}&toDate=${toDate}`,
      {
        dataKey: "result",
        persistBy: "create",
        save: saveData,
        signal,
      }
    );
  }
}
