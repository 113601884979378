/**
 * Session supervision supported types.
 * Used for categories and for translation purposes.
 */
const SessionSupervisionTypeKeys = {
  SupervisedBySelf: "SupervisedBySelf",
  Supervised: "Supervised",
  Unsupervised: "Unsupervised",
};

export { SessionSupervisionTypeKeys };
