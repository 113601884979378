import { Model } from "@vuex-orm/core";
import { parseISO } from "date-fns";
import { getFormattedActiveSide } from "@/utils/activeSide";
import { SessionSupervisionTypeKeys } from "@/utils/sessionSupervision";
import Store from "@/store";
import ActivityDescription from "@/models/ActivityDescription.model";
import gestureProviderIdToMovementId from "@/models/utils";
import DateTimeOffset from "@/models/DateTimeOffset.model";
import { SessionSupervisionMode } from "@/models/SessionReport.model";
export default class PerformanceActivityResult extends Model {
  static entity = "performanceactivityresults";
  static primaryKey = "cloudId"; // Sets the primary key

  static fields() {
    return {
      cloudId: this.attr(null), // Primary key
      id: this.attr(null),
      appId: this.attr(null),
      score: this.attr(null),
      date: this.attr(null),
      started: this.attr(null),
      startOffset: this.attr(null),
      ended: this.attr(null),
      endOffset: this.attr(null),
      activeTrainingTime: this.attr(null),
      compensationDuration: this.attr(null),
      compensationFeedbackResults: this.attr(null),
      gestureProviderResults: this.attr([]),
      level: this.attr(null),
      duration: this.attr(null),
      activeSide: this.attr(null),
      activityName: this.attr(null),
      supervisionMode: this.attr(null),
      therapistUsername: this.attr(null),
      trainedFunctions: this.attr([]),
      // Relation to other data,
      // See ActivityDescription() for what is used as a lazy loader of this data
      activityDescription: this.hasOne(ActivityDescription, "appId"),
    };
  }

  get Date() {
    return this.date ? parseISO(this.date) : null;
  }

  get Started() {
    return this.started ? parseISO(this.started) : null;
  }

  get Ended() {
    return this.ended ? parseISO(this.ended) : null;
  }

  get StartOffset() {
    return this.startOffset ? new DateTimeOffset(this.startOffset) : null;
  }

  get EndOffset() {
    return this.endOffset ? new DateTimeOffset(this.endOffset) : null;
  }

  get SessionType() {
    let username = Store.getters["Auth/username"];
    if (username && this.therapistUsername === username) {
      return this.supervisionMode === SessionSupervisionMode.Unsupervised
        ? SessionSupervisionTypeKeys.Unsupervised
        : SessionSupervisionTypeKeys.SupervisedBySelf;
    }

    return this.supervisionMode === SessionSupervisionMode.Unsupervised
      ? SessionSupervisionTypeKeys.Unsupervised
      : SessionSupervisionTypeKeys.Supervised;
  }

  get ActiveSide() {
    return getFormattedActiveSide(this.activeSide);
  }

  /**
   * Used to lazy load the activity description only if needed.
   */
  get ActivityDescription() {
    // Cache the data for performance efficiency purposes
    if (!this.activityDescription) {
      this.activityDescription = ActivityDescription.query().find(this.appId);
    }
    return this.activityDescription;
  }

  /**
   * Provides gesture provider results and build the minimum data set if nothing available
   * This permits to get data for activity with some missing results.
   */
  fixupGestureProviderResults() {
    if (
      this.gestureProviderResults !== null &&
      this.gestureProviderResults.length > 0
    ) {
      return this.gestureProviderResults;
    }
    // Constructs minimal dataset for quantity parsing
    let minimalResults = [];
    for (let movementId of this.MovementIds) {
      minimalResults.push({
        gestureProviderId: movementId,
        side: this.ActiveSide,
        normalizedMovement: 0,
      });
    }
    this.gestureProviderResults = minimalResults;

    return this.gestureProviderResults;
  }

  /**
   * Provides the overall movement sides found in the gesture provider results
   */
  get AllMovementSides() {
    // NOTICE: We, for now, don't follow the gesture provider side
    // but the activity trained side
    // Hence the code has been adapted to consider the available sides
    // to only be the activity trained side.
    return [this.ActiveSide];

    if (
      this.gestureProviderResults === null ||
      this.gestureProviderResults.length === 0
    ) {
      return [];
    }

    return this.gestureProviderResults.reduce((acc, value) => {
      let movementSide = getFormattedActiveSide(value.side);
      if (!acc.some((e) => e === movementSide)) {
        acc.push(movementSide);
      }
      return acc;
    }, []);
  }

  /**
   * Provides the movement sides for the given movementId, found in activity's detailed movements (without duplicates)
   * N.B.: This is side of the movement, not the training side of the activity
   * @return {*[]}
   */
  getMovementSides(movementId) {
    if (!this.ActivityDescription) {
      return [];
    }

    return this.ActivityDescription.detailedMovements.reduce((acc, value) => {
      //get trained sides from trained activity detailed movements.
      let movementSide = this.ActiveSide;
      if (
        value &&
        (movementId === "All" || movementId === value) &&
        !acc.some((e) => e === movementSide)
      ) {
        acc.push(movementSide);
      }
      return acc;
    }, []);
  }

  /**
   * Provides the detailed movement ids array if available,
   * and then on the global movement id as a fallback.
   */
  get MovementIds() {
    if (this.DetailedMovementIds.length > 0) {
      return this.DetailedMovementIds;
    }

    if (this.GlobalMovementId) {
      return [this.GlobalMovementId];
    }

    return [];
  }

  /**
   * Provides the activity global movement Id
   */
  get GlobalMovementId() {
    return this.ActivityDescription?.globalMovementId || "";
  }

  /**
   * Returns the detailed movement ids from the activity
   */
  get DetailedMovementIds() {
    return this.ActivityDescription?.detailedMovements || [];
  }

  /**
   * Returns the normalized movement for the given movement type
   * @param movementId
   * @return {number|*}
   */
  getStandardizedMovementUnits(movementId, movementSide) {
    if (
      !this.gestureProviderResults ||
      this.gestureProviderResults.length === 0 ||
      !movementId
    ) {
      return 0;
    }

    return this.gestureProviderResults.reduce((acc, value) => {
      let currentMovementId = gestureProviderIdToMovementId(
        value.gestureProviderId
      );

      // NOTICE: We, for now, don't follow the gesture provider side
      // but the activity trained side
      // Hence the code has been adapted to consider the available sides
      // to only be the activity trained side.
      let currentSide = this.ActiveSide;
      movementSide = "All"; // The side is forcefully the one from the activity for now

      if (
        (currentMovementId !== movementId && movementId !== "All") ||
        (currentSide !== movementSide && movementSide !== "All") ||
        value.normalizedMovement == null
      ) {
        return acc;
      }
      return acc + parseFloat(value.normalizedMovement.toFixed(2));
    }, 0);
  }

  get TotalStandardizedMovementUnits() {
    if (
      this.gestureProviderResults !== null &&
      this.gestureProviderResults.length > 0
    ) {
      return this.gestureProviderResults.reduce((acc, value) => {
        if (value.normalizedMovement == null) {
          return acc;
        }
        return acc + value.normalizedMovement;
      }, 0);
    }
    return 0;
  }

  /**
   * Indicates whether the activity results has some valid compensation details.
   * It also returns false when the compensation feedback result is 'Legacy'
   * @return {boolean}
   */
  get HasCompensationDetails() {
    if (this.compensationDuration === null) {
      return false;
    }

    if (this.compensationFeedbackResults === null) {
      return false;
    }

    // Do not consider legacy feedback results
    if (
      this.compensationFeedbackResults.some((result) => result.id === "Legacy")
    ) {
      return false;
    }

    // Returns then whether some details are provided
    return this.compensationFeedbackResults.some((result) => {
      if (result.details === null || result.details.length === 0) {
        return false;
      }

      return result.details.some((detail) => detail.duration !== null);
    });
  }
}
