import { Model } from "@vuex-orm/core";
import { parseISO, differenceInDays } from "date-fns";
import { formatToyyyyMMdd } from "@/utils/date";

export default class DayTrainingSummary extends Model {
  static entity = "dayTrainingSummary";
  static primaryKey = ["date", "patientUsername"];

  static fields() {
    return {
      date: this.attr(null),
      patientUsername: this.attr(null),
      hasPlan: this.attr(null),
      hasMissedPlan: this.attr(null),
      hasUnsupervisedSession: this.attr(null),
      hasSupervisedSession: this.attr(null),
      hasSessionSupervisedByCaller: this.attr(null),
    };
  }

  get Date() {
    return this.date ? parseISO(this.date) : null;
  }

  static async fetch(patientUsername, dateStart, dateEnd) {
    return this.api().get(
      `patient-performance/${patientUsername}/summary?from=${dateStart}&to=${dateEnd}`,
      { dataKey: "result" }
    );
  }

  /**
   * Fetches the patient training summary data for the requested date range
   * Used to display the training data in the performance timeline.
   * @param patientUsername
   * @param startDate
   * @param endDate
   * @return [DayTrainingSummary]
   */
  static async fetchForRange(patientUsername, startDate, endDate) {
    const startingDay = formatToyyyyMMdd(startDate);
    const endingDay = formatToyyyyMMdd(endDate);

    return this.fetch(patientUsername, startingDay, endingDay);
  }

  /**
   * Returns the stored training summaries for the given range and patient
   * @param patientUsername
   * @param startDate
   * @param endDate
   * @return [DayTrainingSummary]
   */
  static async getForRange(patientUsername, startDate, endDate) {
    return DayTrainingSummary.query()
      .where("patientUsername", patientUsername)
      .where((record) => startDate <= record.Date && record.Date <= endDate)
      .get();
  }

  /**
   * Gets the data for the given range but fetches the data from startDate if not available
   * This is used by both the timeline and the calendar selector for the performance report.
   */
  static getForRangeAndFetchIfNeeded(patientUsername, startDate, endDate) {
    return this.getForRange(patientUsername, startDate, endDate).then(
      (entries) => {
        if (
          !entries?.length ||
          entries?.length < differenceInDays(endDate, startDate)
        ) {
          return this.fetchForRange(patientUsername, startDate, endDate).then(
            () => {
              return this.getForRange(patientUsername, startDate, endDate);
            }
          );
        }
        return entries;
      }
    );
  }
}
